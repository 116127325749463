import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FutureShipmentSortBy, FutureShipmentViewModel } from 'schema/serverTypes';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { IconSprite } from '../icons';
import { themeOrange as theme } from 'theme';
import { TableSortLabel } from '../data-grid/TableSortLabel';
import { formatDate } from '../utils';

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      backgroundColor: theme.palette.common.white,
      fontSize: 12,
      color: theme.palette.common.black,
      display: 'grid',
      gridTemplateColumns: '116px 90px 5fr 4fr 3fr 26px 40px',
    },
    th: {
      fontSize: 12,
      color: theme.palette.text.secondary,
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 6,
      paddingRight: 6,
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
    right: {
      textAlign: 'right',
      justifyContent: 'flex-end',
    },
    td: {
      paddingLeft: 6,
      paddingRight: 6,
      '& > a': {
        color: theme.palette.secondary.dark,
        '&:hover': {
          textDecoration: 'underline',
          color: theme.palette.primary.main,
        },
      },
    },
    rowWrapper: {
      display: 'contents',
      backgroundColor: 'green',
      '&:hover > div': {
        backgroundColor: theme.palette.background.light,
      },
      '& > div': {
        paddingTop: 16,
        paddingBottom: 16,
        borderBottom: `2px solid ${theme.palette.background.default}`,
      },
    },
    contractNumber: {
      paddingLeft: 20,
      paddingRight: 6,
      '& > a': {
        color: theme.palette.secondary.dark,
        '&:hover': {
          textDecoration: 'underline',
          color: theme.palette.primary.main,
        },
      },
    },
  })
);

type FutureShipmentTableProps = {
  rows: FutureShipmentViewModel[];
  isLoading: boolean;
  sorting: any;
};

export const FutureShipmentTable = (props: FutureShipmentTableProps) => {
  const { rows, sorting } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <div className={classes.table}>
        <div className={clsx(classes.th)}>
          <TableSortLabel columnName={FutureShipmentSortBy.expectedShipmentDate} sorting={sorting}>
            {t('Expected shipping date')}
          </TableSortLabel>
        </div>
        <div className={clsx(classes.th, classes.right)}>{t('Contract')}</div>
        <div className={clsx(classes.th)} />
        <div className={clsx(classes.th)}>{t('Controls')}</div>
        <div className={clsx(classes.th)}>{t('Nbv')}</div>
        <div className={classes.th} />
        {rows.map((row) => {
          return <TableItem key={row.contractName} values={row} />;
        })}
      </div>
    </>
  );
};

const TableItem = ({ values }: { values: FutureShipmentViewModel }) => {
  const classes = useStyles();
  const {
    expectedShipmentDate,
    contractName,
    lesseeName,
    dealerName,
    quotaManagerName,
    issueAssigneeName,
    issueId,
    nbv,
    controls,
    nomenclatures,
  } = values;
  const { pathname } = useLocation();

  return (
    <>
      <div className={classes.rowWrapper}>
        <div className={clsx(classes.contractNumber, classes.right)}>
          {formatDate(expectedShipmentDate)}
        </div>
        <div className={clsx(classes.td, classes.right)}>
          Договор:
          <br />
          Расчет:
          <br />
          ЛП:
          <br />
          Поставщик:
          <br />
          Менеджер:
          <br />
          Сопровождеждение:
          <br />
        </div>
        <div className={classes.td}>
          <Link to={`/contracts`}>№ 14007-24-LA | Booked Partly Deal Activated {contractName}</Link>
          <br />
          <Link to={`/quotas/calculator/1102`}>№ 45674</Link>
          <br />
          ИП Струбалин Антон Владимирович {lesseeName}
          <br />
          ООО Агро-Нова {dealerName}
          <br />
          Александр Ежов {quotaManagerName}
          <br />
          Иван Иванов | №1340 {issueAssigneeName} | {issueId}
        </div>
        <div className={clsx(classes.td)}>
          Контроли{' '}
          {controls.map((control) => (
            <div key={control.name}>{control.name}</div>
          ))}
        </div>
        <div className={clsx(classes.td, classes.right)}>{nbv}</div>
        <div className={clsx()}>
          <Link to={`${pathname}/comments`}>
            <IconSprite icon="chat" width="14px" color={theme.palette.text.secondary} />
          </Link>
        </div>
      </div>
      <div>
        {nomenclatures.map((nomenclature) => (
          <div key={nomenclature.name}>{nomenclature.name}</div>
        ))}
      </div>
    </>
  );
};
