import {
  createStyles,
  makeStyles,
  MenuItem,
  TextField as MuiTextField,
  Theme,
} from '@material-ui/core';
import { formatNumber, Grid, Select, Switch } from 'components';
import { AmountType, CalculationMethod, UserShortViewModel } from 'schema/serverTypes';
import { useTranslation } from 'react-i18next';
import { FixedAmountInput } from '../AmountInput';
import { useCalculationFormContext } from '../types';
import { ResidualValueInput } from './ResidualValueInput';
import { FundingAmountInput } from './FundingAmountInput';
import { GeneraleRateInput } from './GeneraleRateInput';
import React, { Dispatch, SetStateAction } from 'react';
import { PrepaymentAmountInput } from './PrepaymentAmountInput';
import { useUsersBackendQuery1 } from '../../../../services';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
    },
  })
);

type LeaseContractFieldsProps = {
  totalMargin?: number;
  setCalculationMethodChange: Dispatch<SetStateAction<number>>;
  isCofDisabled: boolean;
  setIsCofDisabled: Dispatch<SetStateAction<boolean>>;
  scoringIssueId?: number;
};

export const LeaseContractFields = ({
  totalMargin,
  setCalculationMethodChange,
  isCofDisabled,
  setIsCofDisabled,
  scoringIssueId,
}: LeaseContractFieldsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { control } = useCalculationFormContext();

  const { data: user } = useUsersBackendQuery1<UserShortViewModel>({
    relativeUrl: 'me',
    queryKey: 'me',
  });

  return (
    <div className={classes.wrapper}>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item xl={4} md={7} xs={24}>
          {/*<AmountInput*/}
          {/*  name="prepayment"*/}
          {/*  label={t('Prepayment')}*/}
          {/*  required*/}
          {/*  allowZero*/}
          {/*  maxPercents={49}*/}
          {/*/>*/}
          <PrepaymentAmountInput />
        </Grid>
        <Grid item xl={2} md={4} xs={24}>
          <FixedAmountInput
            name="tradeFee"
            label={`${t('Commission')}, %`}
            required
            allowZero
            amountMode={AmountType.Percents}
            max={10.0}
          />
        </Grid>
        <Grid item xl={2} md={3} xs={24}>
          <Select control={control} name="numberOfMonths" label={t('LeaseTerm')} required lowercase>
            {Array.from(Array(60 - 11)).map((_, i) => {
              return (
                <MenuItem key={i + 12} value={i + 12}>
                  {i + 12}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item xl={4} md={5} xs={24}>
          <FundingAmountInput />
        </Grid>
        <Grid item md={5} xs={24}>
          <Switch
            name="hasResidualValue"
            label={t('RV')}
            labelOn={t('Yes')}
            labelOff={t('No')}
            type="checkbox"
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item xl={5} md={7} xs={24}>
          <ResidualValueInput />
        </Grid>
        <Grid item xl={6} md={7} xs={24}>
          <Select
            name="calculationMethod"
            label={t('ScheduleType')}
            required
            onChangeCallback={() => setCalculationMethodChange((prev) => prev + 1)}
          >
            <MenuItem value={CalculationMethod.Annuity}>
              {t('CalculationMethodType.Annuity')}
            </MenuItem>
            <MenuItem value={CalculationMethod.StraightLine}>
              {t('CalculationMethodType.StraightLine')}
            </MenuItem>
            <MenuItem value={CalculationMethod.Seasonal}>
              {t('CalculationMethodType.Seasonal')}
            </MenuItem>
            <MenuItem value={CalculationMethod.SuperSeasonal}>
              {t('CalculationMethodType.SuperSeasonal')}
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xl={2} md={2} xs={24}>
          <FixedAmountInput
            name="cofPercents"
            label={`${t('Cof')}, %`}
            amountMode={AmountType.Percents}
            isLockedButton={!scoringIssueId && user?.canChangeCof}
            required
            setIsCofDisabled={setIsCofDisabled}
            disabled={isCofDisabled}
          />
        </Grid>
        <Grid item xl={2} md={2} xs={24}>
          <FixedAmountInput
            name="marginPercents"
            label={t('Margin')}
            amountMode={AmountType.Percents}
            fractionDigits={4}
            required
          />
        </Grid>
        <Grid item xl={2} md={3} xs={24}>
          <MuiTextField
            name="totalMargin"
            label={t('TotalMargin')}
            value={formatNumber(totalMargin, 4) ?? ''}
            variant="outlined"
            size="small"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xl={3} md={3} xs={24}>
          <GeneraleRateInput />
        </Grid>
      </Grid>
    </div>
  );
};
