import { useCallback } from 'react';
import { useWatch, Control, UseFormSetValue } from 'react-hook-form';
import { FutureShipmentListFilter } from 'schema';
import { Chip } from 'components';

export type FutureShipmentChipsProps = {
  control: Control<FutureShipmentListFilter, object>;
  setValue: UseFormSetValue<FutureShipmentListFilter>;
};

export const FutureShipmentChips = (props: FutureShipmentChipsProps) => {
  const { control, setValue } = props;

  const lesseeInn = useWatch({ control, name: 'lesseeInn' });
  const dealerInn = useWatch({ control, name: 'dealerInn' });

  const onLesseeInnRemove = useCallback(
    (id: string) => {
      const inns = lesseeInn?.filter((v) => v !== id);
      setValue('lesseeInn', inns);
    },
    [lesseeInn, setValue]
  );

  const onDealerInnRemove = useCallback(
    (id: string) => {
      const inns = dealerInn?.filter((v) => v !== id);
      setValue('dealerInn', inns);
    },
    [dealerInn, setValue]
  );

  const lesseeInnChips = lesseeInn?.map((lesseeInn) => {
    return (
      <Chip
        label={lesseeInn}
        id={lesseeInn}
        key={`lesseeInn-${lesseeInn}`}
        onRemove={onLesseeInnRemove}
      />
    );
  });

  const dealerInnChips = dealerInn?.map((dealerInn) => {
    return (
      <Chip
        label={dealerInn}
        id={dealerInn}
        key={`dealerInn-${dealerInn}`}
        onRemove={onDealerInnRemove}
      />
    );
  });

  return (
    <>
      {lesseeInnChips}
      {dealerInnChips}
    </>
  );
};
