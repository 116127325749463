import { UseFormReturn } from 'react-hook-form';
import { CounterpartyFormValues, CounterpartyIndividualFormValues } from '../types';
import {
  CounterpartyStatus,
  CounterpartyViewModel,
  RegionTierValue,
  TypeOptions,
} from 'schema/serverTypes';
import { emptyAddress } from '../CounterpartyEditForm/types';

export type CounterpartyFormProps = Pick<
  UseFormReturn<CounterpartyFormValues>,
  'control' | 'reset'
>;

export type CounterpartyIndividualFormProps = Pick<
  UseFormReturn<CounterpartyIndividualFormValues>,
  'control' | 'reset'
>;

export const defaultTypeOptions: TypeOptions = {
  isDealer: true,
  isInsuranceCompany: true,
  isLessee: true,
  isLessor: true,
};

export const defaultValues: CounterpartyViewModel = {
  inn: '',
  name: '',
  fullName: '',
  transliteratedName: '',
  transliteratedOpf: '',
  ogrn: '',
  opf: '',
  kpp: '',
  okpo: '',
  registrationDate: '',
  isLessee: false,
  isDealer: false,
  isInsuranceCompany: false,
  isLessor: false,
  isVerifiedSupplier: false,
  isInsuranceAccredited: false,
  isVendorSupplier: false,
  isSupportSupplier: false,
  phoneNumber: '',
  email: '',
  legalAddress: emptyAddress,
  actualAddress: emptyAddress,
  mailingAddress: emptyAddress,
  generalCondidionsSellerDate: '',
  generalCondidionsLesseeDate: '',
  heads: [],
  contacts: [],
  requisites: [],
  complementaryActivities: [],
  groups: [],
  typeOptions: defaultTypeOptions,
  tier: RegionTierValue.tier1,
  status: CounterpartyStatus.Uknown,
  isAlreadyExist: false,
  contracts: [],
  isAgent: false,
  isPhysic: false,
  isIndividualEntrepreneur: false,
  isVatPayer: false,
  isDefault: false
};
